import React, { useCallback, useEffect, useState, createRef } from 'react';
import { useForm } from 'react-hook-form';
import { v4 as uuid } from 'uuid';
import { Row, Col, Panel } from 'rsuite';
import Filters, { FiltersForm } from './Filters';
import Reminder, { ReminderProps } from './Reminder';

type RawReminder = {
  date: string;
  title: string;
  past: boolean;
}

type Displayable = {
  ref: React.RefObject<any>;
}

const Reminders: React.FC = () => {
  const [reminders, setReminders] = useState<(ReminderProps & Displayable)[]>([]);
  const [results, setResults] = useState<(ReminderProps & Displayable)[]>([]);
  const { control, handleSubmit, watch, getValues } = useForm<FiltersForm>({
    defaultValues: {
      future: true,
      past: true
    }
  });

  const getResults = useCallback((data: FiltersForm) => {
    setResults(reminders.filter((reminder) => {
      if(data.past && !data.future) {
        // past only
        return reminder.past;
      } else if (data.past && data.future) {
        // past and future
        return true;
      } else if (!data.past && !data.future) {
        // neither
        return false;
      } else if (!data.past && data.future) {
        // future only
        return !reminder.past;
      } else {
        return true;
      }
    }).filter((reminder) => {
      if(!data.dates || !data.dates.startDate || ! data.dates.endDate) {
        return true;
      }

      return data.dates.startDate!.getTime() <= reminder.date.getTime() && reminder.date.getTime() <= data.dates.endDate!.getTime();
    }));
  } ,[reminders]);

  const getReminders = async () => {
    const response = await fetch('/api/reminders');
    const data = await response.json();

    setReminders(data.map((raw: RawReminder): ReminderProps & Displayable => {
      return {
        id: uuid(),
        date: new Date(raw.date),
        title: raw.title,
        past: raw.past,
        ref: createRef()
      }
    }));
  }

  useEffect(() => {
    getReminders();
  }, []);

  const onSubmit = useCallback((data: FiltersForm) => {
    getResults(data);
  }, [getResults]);

  useEffect(() => {
    const subscription = watch((value) => getResults(value));
    return () => subscription.unsubscribe();
  }, [getResults, watch]);

  useEffect(() => {
    getResults(getValues());
  }, [getResults, getValues, reminders])

  return <div>
    <Row>
      <Col>
        <h3>
          Upcoming reminders
        </h3>

        <p>
          The below list shows the reminders that are known about in the system.
        </p>

        <p>
          Use the filters to refine what you see.
        </p>
      </Col>
    </Row>
    
    <Row className="d-md-none my-3">
      <Col xs={24}>
        <Panel header="Filters" collapsible bordered>
          <Filters control={control} handleSubmit={handleSubmit} onSubmit={onSubmit} isMobile={true} />
        </Panel>
      </Col>
    </Row>

    <Row>
      <Col md={6} className="d-none d-md-block">
        <Filters control={control} handleSubmit={handleSubmit} onSubmit={onSubmit} />
      </Col>

      <Col xs={24} md={18}>
        <Row>
          <Col>
            <h3>Reminders</h3>
          </Col>
        </Row>

        {results.length === 0 && <Row>
          <Col>
            <p>
              No results to see here
            </p>
          </Col>
        </Row>}

        {results.map((reminder) => {
          return <Reminder key={reminder.id} {...reminder} />;
        })}
      </Col>
    </Row>
  </div>;
};

export default Reminders;