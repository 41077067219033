import React, { useCallback, useState } from 'react';
import { Row, Col, Form, Message } from 'rsuite';
import { useForm, Controller } from 'react-hook-form';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import classnames from 'classnames';

import 'react-phone-number-input/style.css';
import './unsubscribe.scss';

type SubscribeForm = {
  telephoneNumber: string;
}

type BannerInfo = {
  type: 'success' | 'error';
  status: number;
  message: string;
}

const Subscribe: React.FC = () => {
  const [banner, setBanner] = useState<BannerInfo | undefined>();
  const { control, handleSubmit } = useForm<SubscribeForm>();

  const sendToServer = async (number: string) => {
    const result = await fetch(`/api/unsubscribe`, {
      method: 'POST',
      body: JSON.stringify({
        phoneNumber: number
      }),
      headers: {
        'Content-Type': 'application/json'
      },
    });

    if(result.status === 200) {
      setBanner({
        type: 'success',
        message: 'You have successfully unsubscribed from TCP reminders',
        status: result.status
      });
    } else {
      setBanner({
        type: 'error',
        message: 'An error occurred',
        status: result.status
      });
    }
  };

  const onSubmit = useCallback((data: SubscribeForm) => {
    setBanner(undefined);
    sendToServer(data.telephoneNumber);
  }, []);

  return <div className="subscribe">
    <Row className="mb-3">
      <Col>
        <h3>
          Unsubscribe
        </h3>
        
        <p>
          Please use the input box below to remove your number to the reminder service.
        </p>

        <p>
          Removal is immediate and can only be undone be signing up again.
        </p>
      </Col>
    </Row>

    {banner && <Message type={banner.type}>
      {banner.status !== 200 && <>
        <b>{banner.status}</b>:
      </>}
      {' '}
      {banner.message}
    </Message>}

    <Row>
      <Col xs={24} md={8}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="telephoneNumber"
            control={control}
            rules={{
              required: 'Please provide a phone number',
              validate: (value: string) => {
                if(isValidPhoneNumber(value)) {
                  return true
                } else {
                  return 'Please enter a valid phone number';
                }
              }
            }}
            render={({ fieldState: { error }, field: { onChange, value } }) => {
              const classes = classnames('form-group mb-3', {
                error: error
              });

              return <Form.Group className={classes} controlId="formBasicEmail">
                  <Form.ControlLabel>Telephone number</Form.ControlLabel><br />
                  <PhoneInput
                    className='form-control'
                    value={value}
                    onChange={onChange}
                    defaultCountry="GB"
                    id="phone-input"
                  />
                  {error && <Form.HelpText>{error?.message}</Form.HelpText>}
                </Form.Group>
              }
            } 
          />
          
          <input type="submit" className="btn btn-primary" />
        </form>
      </Col>
    </Row>
  </div>;
};

export default Subscribe;