export type SerializedAttendance = {
  invited: number;
  accepted: number;
  rejected: number;
}

class Attendance {
  private _invited: number;
  private _accepted: number;
  private _rejected: number;

  constructor(invited: number = 0, accepted: number = 0, rejected: number = 0) {
    this._invited = invited;
    this._accepted = accepted;
    this._rejected = rejected;
  }

  get invited(): number {
    return this._invited;
  }

  get accepted(): number {
    return this._accepted;
  }

  get rejected(): number {
    return this._rejected;
  }

  toJSON(): SerializedAttendance {
    return {
      invited: this._invited,
      accepted: this._accepted,
      rejected: this._rejected
    };
  }
}

export default Attendance;