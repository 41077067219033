import React from 'react';
import { Controller, Control, UseFormHandleSubmit } from 'react-hook-form';
import { Form, DateRangePicker, Toggle } from 'rsuite';

type FilterProps = {
  control: Control;
  handleSubmit: UseFormHandleSubmit<FiltersForm>;
  onSubmit: (data: FiltersForm) => void;
  isMobile?: boolean;
}

type DateRange = {
  startDate?: Date | null;
  endDate?: Date | null;
}

export type FiltersForm = {
  past?: boolean | undefined;
  future?: boolean | undefined;
  dates?: DateRange | undefined;
}

const Filters: React.FC<FilterProps> = ({control, handleSubmit, onSubmit, isMobile}) => {
  const size = isMobile ? 'lg' : 'md';

  return <form onSubmit={handleSubmit(onSubmit)}>
    <h4>Filters</h4>

    <Controller
      control={control}
      name="dates"
      render={({ fieldState: { error }, field: { onChange, value } }) => {
        return <Form.Group className="mb-3" controlId="show-future-events">
          <Form.ControlLabel>Date range</Form.ControlLabel>
          <DateRangePicker 
            showOneCalendar={isMobile}
            onChange={(dates) => {
              const startDate = dates ? dates[0] : null;
              const endDate = dates ? dates[1] : null;

              onChange({
                startDate,
                endDate
              });
            }}
          />
          <Form.HelpText>{error?.message}</Form.HelpText>
        </Form.Group>
      }}
    />

    <Controller
      control={control}
      name="past"
      render={({ fieldState: { error }, field: { onChange, value } }) => {
        return <Form.Group className="mb-3" controlId="show-past-events">
          <Toggle
            id="past-event-switch"
            checked={value}
            onChange={onChange}
            size={size}
          />
          {' '}
          <Form.ControlLabel>Past events</Form.ControlLabel>
          <Form.HelpText>{error?.message}</Form.HelpText>
        </Form.Group>
      }}
    />

    <Controller
      control={control}
      name="future"
      render={({ fieldState: { error }, field: { onChange, value } }) => {
        return <Form.Group className="mb-3" controlId="show-future-events">
          <Toggle
            id="future-event-switch"
            checked={value}
            onChange={onChange}
            size={size}
          />
          {' '}
          <Form.ControlLabel>Future events</Form.ControlLabel>
          <Form.HelpText>{error?.message}</Form.HelpText>
        </Form.Group>
      }}
    />
  </form>;
};

export default Filters;