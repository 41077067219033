import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Row, Col, Progress, Form } from 'rsuite';
import Event from '../../models/Event';
import { useParams } from 'react-router-dom';
import getPercentage from '../../utils/getPercentage';

import './count.scss';

const Count: React.FC = () => {
  const { eventId } = useParams();
  const [event, setEvent] = useState<Event>(new Event({
    attendance: {
      accepted: 0,
      invited: 19,
      rejected: 0
    },
    location: {
      address: '',
      name: ''
    },
    reminder: new Date(),
    start: new Date(),
    uid: '-1'
  }));

  useEffect(() => {
   const getData = async () => {
    const response = await fetch(`/api/find/${eventId}`);
    const data = await response.json();
    
    setEvent(new Event(data));
   }

   getData();
  }, [eventId]);

  return <div>
    <Row className='mb-3'>
      <Col>
        <h3>
          {event.location.name}
          <span>
            {moment(event.start).format('DD-MM-YYYY')}
          </span>
        </h3>

        <p>
          Thank you for replying to the reminder about TCP.
        </p>

        <p>
          Please use the below graphic to see how many people are coming to TCP on {moment(event.start).format('DD-MM-YYYY')}.
        </p>

        <p>
          As a reminder TCP with be happening at {event.location.name} at {moment(event.start).format('HH:mm')}.
        </p>
      </Col>
    </Row>

    <Row>
      <Col xs={24} sm={24} md={24} lg={24}>
        <Form.ControlLabel>Accepted ({event.attendance.accepted})</Form.ControlLabel>
        <Progress.Line status="success" percent={getPercentage(event.attendance.invited, event.attendance.accepted)} key={1} showInfo={false} />
        
        <Form.ControlLabel>Unknown ({event.attendance.invited - (event.attendance.accepted + event.attendance.rejected)})</Form.ControlLabel>
        <Progress.Line status="active" percent={100 - getPercentage(event.attendance.invited, (event.attendance.accepted + event.attendance.rejected))} key={2} showInfo={false} />
        
        <Form.ControlLabel>Rejected ({event.attendance.rejected})</Form.ControlLabel>
        <Progress.Line status="fail" percent={getPercentage(event.attendance.invited, event.attendance.rejected)} key={3} showInfo={false} />
      </Col>
    </Row>
  </div>;
};

export default Count;