export type SerializedLocation = {
  address: string;
  name: string;
}

class Location {
  private _address: string;
  private _name: string;

  constructor(address: string | SerializedLocation) {
    if (typeof address !== 'string') {
      const parsedAddress = address as SerializedLocation;
      this._address = parsedAddress.address;
      this._name = parsedAddress.name;
    } else {
      this._address = address;
      this._name = address.split(',', 1)[0];
    }
  }

  get address(): string {
    return this._address ?? 'UNKNOWN';
  }

  get name(): string {
    return this._name ?? 'UNKNOWN';
  }

  toJSON(): SerializedLocation {
    return {
      address: this._address,
      name: this._name
    };
  }
}

export default Location;