import React from 'react';

import './404.scss';

const NoMatch: React.FC = () => {
  return <div className="error-page d-flex justify-content-center align-items-center">
    <div className="status">
      404
    </div>

    <p className="message">
      The page you requested was not found.
    </p>
  </div>;
};

export default NoMatch;