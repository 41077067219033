import React from 'react';
import Event from '../../models/Event';
import moment from 'moment';

type OptionProps = {
  event: Event;
}

const CustomOption: React.FC<OptionProps> = ({event: data}) => {
  return <div>
    <div>
      {data.location.name}
    </div>

    <div>
      {moment(data.start).format('DD-MM-YYYY')}
    </div>
  </div>;
};

export default CustomOption;