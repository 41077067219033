import React, { useEffect, useState, useMemo } from 'react';
import moment from 'moment';
import { Row, Col } from 'rsuite';
import Event, { SerializedEvent } from '../../models/Event';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartData
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  plugins: {
    title: {
      display: true,
      text: 'Event attendance comparison',
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};


const Compare: React.FC = () => {
  const [list, setList] = useState<Event[]>([]);

  const fetchList = async () => {
    const response = await fetch('/api/list');
    const data = await response.json();

    setList(data.map((event: SerializedEvent) => {
      return new Event(event);
    }));
  };

  useEffect(() => {
    fetchList();
  }, []);

  const data = useMemo(() => {
    const labels = list.map((event) => {
      return moment(event.start).format('DD-MM-YYYY');
    });

    const data: ChartData<'bar', number[], string> = {
      labels,
      datasets: [{
        label: 'Accepted',
        data: list.map((event) => event.attendance.accepted),
        backgroundColor: 'rgb(25, 135, 84)'
      }, {
        label: 'Unknown',
        data: list.map((event) => {
          const { attendance } = event;
          return attendance.invited - (attendance.accepted + attendance.rejected);
        }),
        backgroundColor: 'rgb(255, 193, 7)',
      }, {
        label: 'Rejected',
        data: list.map((event) => event.attendance.rejected),
        backgroundColor: 'rgb(220, 53, 69)',
      }]
    };

    return data;
  }, [list]);

  return (
    <div>
      <Row>
        <Col>
          <h3>
            Attendance comparison
          </h3>

          <p>
            The belore graph shows attendance for all known events, past and future.
          </p>

          <p>
            Use it to descern patterns in attendance over the course of approx. 1 year.
          </p>
        </Col>
      </Row>

      <Row>
        <Col xs={24}>
          {list.length > 0 && <Bar options={options} data={data} />}
        </Col>
      </Row>
    </div>
  );
};

export default Compare;