import React from 'react';
import { Row, Col } from 'rsuite';
import moment from 'moment';
import classnames from 'classnames';

import './reminder.scss';

export type ReminderProps = {
  id: string;
  date: Date;
  title: string;
  past: boolean;
}

const Reminder: React.FC<ReminderProps> = React.forwardRef(({title, date, past}, ref) => {
  const classes = classnames('reminder', {
    past
  });

  return <Row className={classes}>
      <Col xs={24} md={16}>
        <div className="p-3 entry">
          <h4>{title}</h4>
          <p>{moment(date).format('DD-MM-YYYY')}</p>
        </div>
      </Col>
    </Row>;
});

export default Reminder;