import React, { useCallback, useEffect, useState } from 'react';
import { Button, Grid, Row, Col, Navbar, Nav } from 'rsuite';
import { useLocation, Link } from "react-router-dom";
import { Outlet } from 'react-router-dom';
import classnames from 'classnames';
import logo from '../assets/logo.png';
import { TiThMenuOutline } from 'react-icons/ti';


import 'rsuite/dist/rsuite.min.css';
import './index.scss';

const Layout: React.FC = () => {
  const [active, setActive] = useState<string>('/');
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    const { pathname } = location;

    setActive(pathname);
  }, [location]);

  const toggleMenu = useCallback(() => {
    setMenuOpen(!menuOpen);
  }, [menuOpen]);

  const classes = classnames('me-auto', {
    closed: !menuOpen,
    open: menuOpen
  });

  return <>
    <Grid>
      <Row as="header">
        <Col xs={24}>
          <a href="https://parishofbasingstokedown.org.uk" target="_blank" rel="noreferrer">
            <img src={logo} alt="Parish of Basingstoke Down logo" />
            <h1 className="d-none d-md-block pt-3">
              <span>THE PARISH OF</span>
              BASINGSTOKE DOWN
            </h1>
          </a>
        </Col>
      </Row>

      <Row>
        <Col className="px-0" xs={24}>
          <Navbar>
            <Navbar.Brand as={Link} to="/">
              The Cana Project

              <Button className="menu-toggle" appearance="ghost" onClick={toggleMenu}>
                <TiThMenuOutline />
              </Button>
            </Navbar.Brand>
            <Nav className={classes} activeKey={active}>
              <Nav.Item as={Link} className="py-2" eventKey="/" to="/">Home</Nav.Item>
              <Nav.Item as={Link} className="py-2" eventKey="/subscribe" to="/subscribe">Subscribe</Nav.Item>
              <Nav.Item as={Link} className="py-2" eventKey="/unsubscribe" to="/unsubscribe">Unsubscribe</Nav.Item>
              <Nav.Item as={Link} className="py-2" eventKey="/find" to="/find">Find event</Nav.Item>
              <Nav.Item as={Link} className="py-2" eventKey="/compare" to="/compare">Compare events</Nav.Item>
              <Nav.Item as={Link} className="py-2" eventKey="reminders" to="/reminders">See reminders</Nav.Item>
            </Nav>
          </Navbar>
        </Col>
      </Row>

      <Outlet />
    </Grid>

    <footer className="py-3 mt-3">
      <Grid>
        <Row>
          <Col>
            &copy; Archronos Ltd.
          </Col>

          <Col>
            Designed and developed by Steve Coleman-Williams
          </Col>

          <Col className="d-none d-md-block">

          </Col>
        </Row>
      </Grid>
    </footer>
  </>;
};

export default Layout;