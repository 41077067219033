import React from 'react';
import { Row, Col} from 'rsuite';

const Home: React.FC = () => {
  return <div>
    <Row>
      <Col md={14} sm={24}>
        <h3>
          Welcome
        </h3>
        
        <p>
          Welcome to the reminder system for The Cana Project.
        </p>

        <p>
          This system simply serves as a reminder for when and where TCP is happening. If you subscribe you will be sent an SMS 24hrs
          before the event reminding you, and asking you to send in an RSVP.
        </p>

        <p>
          Please do send an RSVP as this allows others to know whether or not they will be alone at the pub.
        </p>
      </Col>
    </Row>
  </div>;
};

export default Home;