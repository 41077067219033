import React from 'react';
import { Route, Routes } from 'react-router-dom';
import classnames from 'classnames';
import { Grid } from 'rsuite';
import Layout from './pages';
import Home from './pages/home';
import Count from './pages/count';
import NoMatch from './pages/no-match';
import Subscribe from './pages/subscribe';
import Unsubscribe from './pages/unsubscribe';
import Find from './pages/find';
import Compare from './pages/compare';
import Reminders from './pages/reminders';

import './App.scss';

const App: React.FC = () => {

  const classes = classnames('App', {
    dark: window.matchMedia('(prefers-color-scheme: dark)').matches
  });

  return (
    <div className={classes}>
      <Grid>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/count/:eventId" element={<Count />} />
            <Route path="/subscribe" element={<Subscribe />} />
            <Route path="/unsubscribe" element={<Unsubscribe />} />
            <Route path="/find" element={<Find />} />
            <Route path="/compare" element={<Compare />} />
            <Route path="/reminders" element={<Reminders />} />
            <Route path="*" element={<NoMatch />} />
          </Route>
        </Routes>
      </Grid>
    </div>
  );
}

export default App;
