import React, { useEffect, useState, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Row, Col, Form, SelectPicker } from 'rsuite';
import { useNavigate } from "react-router-dom";
import classnames from 'classnames';

import Event, { SerializedEvent } from '../../models/Event';

import CustomOption from './Option';

type FindForm = {
  selectedEvent: Event;
};

const Find: React.FC = () => {
  const [list, setList] = useState<Event[]>([]);
  const { control, handleSubmit } = useForm<FindForm>();
  const navigate = useNavigate();

  const fetchList = async () => {
    const response = await fetch('/api/list');
    const data = await response.json();

    setList(data.map((event: SerializedEvent) => {
      return new Event(event);
    }));
  };

  useEffect(() => {
    fetchList();
  }, []);

  const onSubmit = useCallback((data: FindForm) => {
    navigate(`/count/${data.selectedEvent.uid}`);
  }, [navigate]);

  return <div>
    <Row className="mb-3">
      <Col>
        <h3>
          Find event
        </h3>
        
        <p>
          Please use the form below to search for past or upcoming events and see the stats.
        </p>

        <p>
          Event data is stored for 1 year before being deleted.
        </p>
      </Col>
    </Row>

    <Row>
      <Col xs={24} md={8}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name="selectedEvent"
            rules={{
              required: 'You must select an event'
            }}
            render={({ fieldState: { error }, field: { onChange, value } }) => {
              const classes = classnames('form-group mb-3', {
                error: error
              });

              return <Form.Group className={classes} controlId="formBasicEmail">
                <Form.ControlLabel>Event</Form.ControlLabel>
                <SelectPicker 
                  block
                  cleanable={false}
                  data={list.map((event) => {
                    return {
                      label: <CustomOption event={event}></CustomOption>,
                      value: event.uid
                    }
                  })}
                  onChange={(newVal) => {
                    const event = list.find((event) => {
                      return event.uid === newVal;
                    });

                    onChange(event);
                  }}
                  searchable={false}
                />
            </Form.Group>
            }}
          />

          <input type="submit" className="btn btn-primary" />
        </form>
      </Col>
    </Row>
  </div>;
};

export default Find;