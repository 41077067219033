import moment from 'moment';

import Location, { SerializedLocation } from './Location';
import Attendance, { SerializedAttendance } from './Attendance';

export type SerializedEvent = {
  location: SerializedLocation;
  attendance: SerializedAttendance;
  uid: string;
  start: Date;
  reminder: Date;
}

class Event {
  private _raw: SerializedEvent;
  private _location: Location;
  private _uid: string;
  private _start: Date;
  private _reminder: Date;
  private _attendance: Attendance = new Attendance();

  constructor(iCalEvent: SerializedEvent) {
    this._raw = iCalEvent;
    this._uid = iCalEvent.uid;
    this._location = new Location(iCalEvent.location);

    this._start = new Date(iCalEvent.start);
    this._reminder = moment(this._start).subtract(1, 'day').toDate();

    if ('attendance' in iCalEvent) {
      const attendance = iCalEvent.attendance;
      this._attendance = new Attendance(attendance.invited, attendance.accepted, attendance.rejected);
    }
  }

  get start(): Date {
    return this._start;
  }

  get location(): Location {
    return this._location;
  }

  get uid(): string {
    return this._uid;
  }

  get attendance(): Attendance {
    return this._attendance;
  }

  toJSON(): SerializedEvent {
    return {
      location: this._location.toJSON(),
      attendance: this._attendance.toJSON(),
      reminder: this._reminder,
      start: this._start,
      uid: this._uid
    };
  }
};

export default Event;